@font-face {
  font-family: 'Mobitrace-DNR';
  src:  url('../assets/fonts/Mobitrace-DNR.eot?xm8i8m');
  src:  url('../assets/fonts/Mobitrace-DNR.eot?xm8i8m#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-DNR.ttf?xm8i8m') format('truetype'),
    url('../assets/fonts/Mobitrace-DNR.woff?xm8i8m') format('woff'),
    url('../assets/fonts/Mobitrace-DNR.svg?xm8i8m#Mobitrace-DNR') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-DNR {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-DNR' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-DNR-0:before {
  content: "\d4";
}
.ic-DNR-1:before {
  content: "\a5";
}
.ic-DNR-2:before {
  content: "\3a9";
}
.ic-DNR-3:before {
  content: "\2211";
}
.ic-DNR-unknown:before {
  content: "\2206";
}
.ic-DNR-unknown-alt:before {
  content: "\b7";
}
@font-face {
  font-family: 'Mobitrace-Infusion';
  src:  url('../assets/fonts/Mobitrace-Infusion.eot?9s59co');
  src:  url('../assets/fonts/Mobitrace-Infusion.eot?9s59co#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-Infusion.ttf?9s59co') format('truetype'),
    url('../assets/fonts/Mobitrace-Infusion.woff?9s59co') format('woff'),
    url('../assets/fonts/Mobitrace-Infusion.svg?9s59co#Mobitrace-Infusion') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Infusion {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Infusion' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-infusion:before {
  content: "\79";
}
.ic-infusion-insert:before {
  content: "\7c";
}
.ic-infusion-pause:before {
  content: "\cd";
}
.ic-infusion-refresh:before {
  content: "\d3";
}
.ic-infusion-speed:before {
  content: "\2030";
}
.ic-infusion-start:before {
  content: "\ce";
}
.ic-infusion-stop:before {
  content: "\cb";
}
.ic-infusion-time:before {
  content: "\fb02";
}
.ic-infusion-volume:before {
  content: "\2265";
}
@font-face {
  font-family: 'Mobitrace-Medication';
  src:  url('../assets/fonts/Mobitrace-Medication.eot?aioath');
  src:  url('../assets/fonts/Mobitrace-Medication.eot?aioath#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-Medication.ttf?aioath') format('truetype'),
    url('../assets/fonts/Mobitrace-Medication.woff?aioath') format('woff'),
    url('../assets/fonts/Mobitrace-Medication.svg?aioath#Mobitrace-Medication') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Medication {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Medication' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-validation-doctor:before {
  content: "\c5";
}
.ic-validation-hold:before {
  content: "\201a";
}
.ic-validation-pharma:before {
  content: "\ca";
}
.ic-medication-dose:before {
  content: "\ef";
}
.ic-medication-dose-alt:before {
  content: "\aa";
}
.ic-medication-high-risk:before {
  content: "\178";
}
.ic-medication-high-risk-bg:before {
  content: "\ba";
}
.ic-medication-high-risk-fg:before {
  content: "\ee";
}
.ic-medication-home:before {
  content: "\2b";
}
.ic-medication-home-alt:before {
  content: "\b1";
}
.ic-medication-narcotic:before {
  content: "\c6";
}
.ic-medication-needed:before {
  content: "\2122";
}
.ic-medication-needed-bg:before {
  content: "\2020";
}
.ic-medication-needed-fg:before {
  content: "\da";
}
@font-face {
  font-family: 'Mobitrace-Questinaire';
  src:  url('../assets/fonts/Mobitrace-Questinaire.eot?aoavfv');
  src:  url('../assets/fonts/Mobitrace-Questinaire.eot?aoavfv#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-Questinaire.ttf?aoavfv') format('truetype'),
    url('../assets/fonts/Mobitrace-Questinaire.woff?aoavfv') format('woff'),
    url('../assets/fonts/Mobitrace-Questinaire.svg?aoavfv#Mobitrace-Questinaire') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Questinaire {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Questinaire' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-questionaire-0-percent:before {
  content: "\5a";
}
.ic-questionaire-50-percent:before {
  content: "\59";
}
.ic-questionaire-75-percent:before {
  content: "\58";
}
.ic-questionaire:before {
  content: "\57";
}
.ic-questionaire-delete:before {
  content: "\e6";
}
.ic-questionaire-done:before {
  content: "\df";
}
.ic-questionaire-new:before {
  content: "\a9";
}
@font-face {
  font-family: 'Mobitrace-Transfusion';
  src:  url('../assets/fonts/Mobitrace-Transfusion.eot?btjtn5');
  src:  url('../assets/fonts/Mobitrace-Transfusion.eot?btjtn5#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-Transfusion.ttf?btjtn5') format('truetype'),
    url('../assets/fonts/Mobitrace-Transfusion.woff?btjtn5') format('woff'),
    url('../assets/fonts/Mobitrace-Transfusion.svg?btjtn5#Mobitrace-Transfusion') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Transfusion {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Transfusion' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-blood-end:before {
  content: "\20ac";
}
.ic-blood-fridge:before {
  content: "\2202";
}
.ic-blood-on-route:before {
  content: "\2021";
}
.ic-blood-on-ward:before {
  content: "\c2";
}
.ic-blood-on-ward-2:before {
  content: "\ea";
}
.ic-blood-ongoing:before {
  content: "\ae";
}
.ic-blood-paused:before {
  content: "\3c0";
}
.ic-blood-retour:before {
  content: "\d2";
}
.ic-blood-stopped:before {
  content: "\f4";
}
.ic-blood-transfusion:before {
  content: "\6d";
}
@font-face {
  font-family: 'Mobitrace-Transport';
  src:  url('../assets/fonts/Mobitrace-Transport.eot?i48bw2');
  src:  url('../assets/fonts/Mobitrace-Transport.eot?i48bw2#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-Transport.ttf?i48bw2') format('truetype'),
    url('../assets/fonts/Mobitrace-Transport.woff?i48bw2') format('woff'),
    url('../assets/fonts/Mobitrace-Transport.svg?i48bw2#Mobitrace-Transport') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Transport {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Transport' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-transport-cancel:before {
  content: "\61";
}
.ic-transport-done:before {
  content: "\62";
}
.ic-transport-ongoing:before {
  content: "\64";
}
.ic-transport-pause:before {
  content: "\65";
}
.ic-transport-request-b:before {
  content: "\66";
}
.ic-transport-request-a:before {
  content: "\67";
}
.ic-transport-request-c:before {
  content: "\68";
}
.ic-transport-to-location-a:before {
  content: "\69";
}
.ic-transport-to-location-b:before {
  content: "\6a";
}
.ic-transport-to-location-c:before {
  content: "\6b";
}
.ic-ambulance:before {
  content: "\63";
}
.ic-stretcher:before {
  content: "\201d";
}
.ic-walking:before {
  content: "\e5";
}
.ic-wheelchair-person:before {
  content: "\bb";
}
@font-face {
  font-family: 'Mobitrace-UI';
  src:  url('../assets/fonts/Mobitrace-UI.eot?5bqp35');
  src:  url('../assets/fonts/Mobitrace-UI.eot?5bqp35#iefix') format('embedded-opentype'),
    url('../assets/fonts/Mobitrace-UI.ttf?5bqp35') format('truetype'),
    url('../assets/fonts/Mobitrace-UI.woff?5bqp35') format('woff'),
    url('../assets/fonts/Mobitrace-UI.svg?5bqp35#Mobitrace-UI') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-UI {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-UI' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-transport-cancel:before {
  content: "\61";
}
.ic-transport-done:before {
  content: "\62";
}
.ic-transport-ongoing:before {
  content: "\64";
}
.ic-transport-pause:before {
  content: "\65";
}
.ic-transport-request-b:before {
  content: "\66";
}
.ic-transport-request-a:before {
  content: "\67";
}
.ic-transport-request-c:before {
  content: "\68";
}
.ic-transport-to-location-a:before {
  content: "\69";
}
.ic-transport-to-location-b:before {
  content: "\6a";
}
.ic-transport-to-location-c:before {
  content: "\6b";
}
.ic-ambulance:before {
  content: "\63";
}
.ic-stretcher:before {
  content: "\201d";
}
.ic-walking:before {
  content: "\e5";
}
.ic-wheelchair-person:before {
  content: "\bb";
}
.ic-battery-0-4:before {
  content: "\e6";
}
.ic-battery-1-4:before {
  content: "\c2";
}
.ic-battery-2-4:before {
  content: "\ea";
}
.ic-battery-3-4:before {
  content: "\ae";
}
.ic-battery-4-4:before {
  content: "\2020";
}
.ic-EWS:before {
  content: "\76";
}
.ic-add:before {
  content: "\6c";
}
.ic-alert:before {
  content: "\6d";
}
.ic-arrow-drop-left:before {
  content: "\6e";
}
.ic-arrowdropright:before {
  content: "\da";
}
.ic-arrowdropdown:before {
  content: "\ba";
}
.ic-arrow-drop-up:before {
  content: "\6f";
}
.ic-back:before {
  content: "\70";
}
.ic-arrow-left:before {
  content: "\71";
}
.ic-arrow-right:before {
  content: "\72";
}
.ic-arrow-up:before {
  content: "\4d";
}
.ic-arrow-down:before {
  content: "\73";
}
.ic-attention:before {
  content: "\74";
}
.ic-attention-solid:before {
  content: "\75";
}
.ic-badge:before {
  content: "\a3";
}
.ic-badge-solid:before {
  content: "\25";
}
.ic-blood:before {
  content: "\77";
}
.ic-browser:before {
  content: "\a1";
}
.ic-calendar:before {
  content: "\78";
}
.ic-camera:before {
  content: "\79";
}
.ic-cancel:before {
  content: "\7a";
}
.ic-check:before {
  content: "\7b";
}
.ic-cleaning:before {
  content: "\7c";
}
.ic-cloud:before {
  content: "\5e";
}
.ic-cloud-download:before {
  content: "\2013";
}
.ic-cloud-upload:before {
  content: "\d8";
}
.ic-coffee:before {
  content: "\24";
}
.ic-connection:before {
  content: "\5b";
}
.ic-construction:before {
  content: "\51";
}
.ic-cool:before {
  content: "\192";
}
.ic-device:before {
  content: "\ab";
}
.ic-edit:before {
  content: "\7d";
}
.ic-equal:before {
  content: "\7e";
}
.ic-filter:before {
  content: "\7f";
}
.ic-flag:before {
  content: "\60";
}
.ic-food:before {
  content: "\80";
}
.ic-forbidden:before {
  content: "\3d";
}
.ic-handwash:before {
  content: "\3a";
}
.ic-home:before {
  content: "\2c";
}
.ic-home-solid:before {
  content: "\3b";
}
.ic-hospital:before {
  content: "\b6";
}
.ic-hospital-solid:before {
  content: "\5c";
}
.ic-info:before {
  content: "\220f";
}
.ic-info-solid:before {
  content: "\152";
}
.ic-injection:before {
  content: "\81";
}
.ic-location:before {
  content: "\40";
}
.ic-loop:before {
  content: "\82";
}
.ic-medication:before {
  content: "\e9";
}
.ic-medication-alt:before {
  content: "\26";
}
.ic-message-delay:before {
  content: "\bf";
}
.ic-message-delay-alt:before {
  content: "\131";
}
.ic-message-new:before {
  content: "\27";
}
.ic-message-new-alt:before {
  content: "\22";
}
.ic-messages:before {
  content: "\a7";
}
.ic-messages-solid:before {
  content: "\f8";
}
.ic-messages-alt:before {
  content: "\28";
}
.ic-messages-alt-solid:before {
  content: "\c7";
}
.ic-messages-attachment:before {
  content: "\2e";
}
.ic-messages-mail:before {
  content: "\203a";
}
.ic-messages-delivered:before {
  content: "\2044";
}
.ic-messages-read:before {
  content: "\a2";
}
.ic-messages-send:before {
  content: "\221a";
}
.ic-messages-send-solid:before {
  content: "\222b";
}
.ic-monitor:before {
  content: "\e8";
}
.ic-more:before {
  content: "\e7";
}
.ic-more-menu:before {
  content: "\21";
}
.ic-more-solid:before {
  content: "\83";
}
.ic-navigation:before {
  content: "\2018";
}
.ic-notes:before {
  content: "\e0";
}
.ic-nurse:before {
  content: "\84";
}
.ic-nurse-solid:before {
  content: "\2a";
}
.ic-options:before {
  content: "\29";
}
.ic-ordering:before {
  content: "\2d";
}
.ic-oxygen:before {
  content: "\2014";
}
.ic-oxygen-alt:before {
  content: "\153";
}
.ic-parameters:before {
  content: "\23";
}
.ic-patient:before {
  content: "\34";
}
.ic-patient-add:before {
  content: "\31";
}
.ic-patient-check:before {
  content: "\32";
}
.ic-patient-delete:before {
  content: "\33";
}
.ic-patients:before {
  content: "\35";
}
.ic-patients-solid:before {
  content: "\b4";
}
.ic-pause:before {
  content: "\36";
}
.ic-people:before {
  content: "\2026";
}
.ic-people-female:before {
  content: "\2260";
}
.ic-people-male:before {
  content: "\f7";
}
.ic-photo:before {
  content: "\37";
}
.ic-printer:before {
  content: "\38";
}
.ic-pulse:before {
  content: "\39";
}
.ic-questionaire-alt:before {
  content: "\30";
}
.ic-radio-active:before {
  content: "\85";
}
.ic-radiobutton:before {
  content: "\50";
}
.ic-radiobutton-checked:before {
  content: "\4e";
}
.ic-radiobutton-filled:before {
  content: "\4f";
}
.ic-refresh:before {
  content: "\fb01";
}
.ic-remove:before {
  content: "\c8";
}
.ic-research:before {
  content: "\25ca";
}
.ic-return:before {
  content: "\cc";
}
.ic-left:before {
  content: "\f8ff";
}
.ic-right:before {
  content: "\2248";
}
.ic-down:before {
  content: "\f9";
}
.ic-up:before {
  content: "\ac";
}
.ic-route-right:before {
  content: "\cf";
}
.ic-routeleft:before {
  content: "\e907";
}
.ic-routereverse:before {
  content: "\e908";
}
.ic-route:before {
  content: "\e909";
}
.ic-sample:before {
  content: "\b0";
}
.ic-sample-info:before {
  content: "\c6";
}
.ic-scan:before {
  content: "\5f";
}
.ic-scan-barcode:before {
  content: "\201e";
}
.ic-scan-icon:before {
  content: "\2039";
}
.ic-search:before {
  content: "\41";
}
.ic-security:before {
  content: "\201c";
}
.ic-selection-off:before {
  content: "\42";
}
.ic-selection-on:before {
  content: "\43";
}
.ic-settings:before {
  content: "\44";
}
.ic-signal:before {
  content: "\45";
}
.ic-smiley:before {
  content: "\eb";
}
.ic-sorting:before {
  content: "\2f";
}
.ic-standby:before {
  content: "\2264";
}
.ic-statistics:before {
  content: "\d9";
}
.ic-stetho:before {
  content: "\46";
}
.ic-stopwatch:before {
  content: "\2022";
}
.ic-stretcher1:before {
  content: "\201f";
}
.ic-tasks:before {
  content: "\47";
}
.ic-tasks-solid:before {
  content: "\2019";
}
.ic-temperature:before {
  content: "\48";
}
.ic-timeline:before {
  content: "\49";
}
.ic-timer:before {
  content: "\4a";
}
.ic-tools:before {
  content: "\52";
}
.ic-trash:before {
  content: "\4b";
}
.ic-tv:before {
  content: "\b5";
}
.ic-walking1:before {
  content: "\ec";
}
.ic-wheelchair-person1:before {
  content: "\bc";
}
.ic-wifi-disabled:before {
  content: "\c1";
}
.ic-wifi-disabled-alternative:before {
  content: "\db";
}
.ic-wifi-high:before {
  content: "\56";
}
.ic-wifi-low:before {
  content: "\53";
}
.ic-wifi-mid:before {
  content: "\54";
}
.ic-wifi-off:before {
  content: "\55";
}
.ic-wound:before {
  content: "\4c";
}
