body {
    margin: 0;
}

#root {
    width: 100%;
    height: 100vh;
}

@font-face {
  font-family: 'Mobitrace-DNR';
  src:  url(/static/media/Mobitrace-DNR.bc5ed9d3.eot);
  src:  url(/static/media/Mobitrace-DNR.bc5ed9d3.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-DNR.792ae69a.ttf) format('truetype'),
    url(/static/media/Mobitrace-DNR.96c5259b.woff) format('woff'),
    url(/static/media/Mobitrace-DNR.1185014d.svg#Mobitrace-DNR) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-DNR {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-DNR' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-DNR-0:before {
  content: "\D4";
}
.ic-DNR-1:before {
  content: "\A5";
}
.ic-DNR-2:before {
  content: "\3A9";
}
.ic-DNR-3:before {
  content: "\2211";
}
.ic-DNR-unknown:before {
  content: "\2206";
}
.ic-DNR-unknown-alt:before {
  content: "\B7";
}
@font-face {
  font-family: 'Mobitrace-Infusion';
  src:  url(/static/media/Mobitrace-Infusion.7bd6aedf.eot);
  src:  url(/static/media/Mobitrace-Infusion.7bd6aedf.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-Infusion.63bc9048.ttf) format('truetype'),
    url(/static/media/Mobitrace-Infusion.7dc7436c.woff) format('woff'),
    url(/static/media/Mobitrace-Infusion.687bf5f4.svg#Mobitrace-Infusion) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Infusion {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Infusion' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-infusion:before {
  content: "y";
}
.ic-infusion-insert:before {
  content: "|";
}
.ic-infusion-pause:before {
  content: "\CD";
}
.ic-infusion-refresh:before {
  content: "\D3";
}
.ic-infusion-speed:before {
  content: "\2030";
}
.ic-infusion-start:before {
  content: "\CE";
}
.ic-infusion-stop:before {
  content: "\CB";
}
.ic-infusion-time:before {
  content: "\FB02";
}
.ic-infusion-volume:before {
  content: "\2265";
}
@font-face {
  font-family: 'Mobitrace-Medication';
  src:  url(/static/media/Mobitrace-Medication.6268ed6f.eot);
  src:  url(/static/media/Mobitrace-Medication.6268ed6f.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-Medication.b101b06e.ttf) format('truetype'),
    url(/static/media/Mobitrace-Medication.5c250683.woff) format('woff'),
    url(/static/media/Mobitrace-Medication.7acd5e60.svg#Mobitrace-Medication) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Medication {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Medication' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-validation-doctor:before {
  content: "\C5";
}
.ic-validation-hold:before {
  content: "\201A";
}
.ic-validation-pharma:before {
  content: "\CA";
}
.ic-medication-dose:before {
  content: "\EF";
}
.ic-medication-dose-alt:before {
  content: "\AA";
}
.ic-medication-high-risk:before {
  content: "\178";
}
.ic-medication-high-risk-bg:before {
  content: "\BA";
}
.ic-medication-high-risk-fg:before {
  content: "\EE";
}
.ic-medication-home:before {
  content: "+";
}
.ic-medication-home-alt:before {
  content: "\B1";
}
.ic-medication-narcotic:before {
  content: "\C6";
}
.ic-medication-needed:before {
  content: "\2122";
}
.ic-medication-needed-bg:before {
  content: "\2020";
}
.ic-medication-needed-fg:before {
  content: "\DA";
}
@font-face {
  font-family: 'Mobitrace-Questinaire';
  src:  url(/static/media/Mobitrace-Questinaire.b8d0404d.eot);
  src:  url(/static/media/Mobitrace-Questinaire.b8d0404d.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-Questinaire.5b68dc1b.ttf) format('truetype'),
    url(/static/media/Mobitrace-Questinaire.a967b9aa.woff) format('woff'),
    url(/static/media/Mobitrace-Questinaire.78af95e1.svg#Mobitrace-Questinaire) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Questinaire {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Questinaire' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-questionaire-0-percent:before {
  content: "Z";
}
.ic-questionaire-50-percent:before {
  content: "Y";
}
.ic-questionaire-75-percent:before {
  content: "X";
}
.ic-questionaire:before {
  content: "W";
}
.ic-questionaire-delete:before {
  content: "\E6";
}
.ic-questionaire-done:before {
  content: "\DF";
}
.ic-questionaire-new:before {
  content: "\A9";
}
@font-face {
  font-family: 'Mobitrace-Transfusion';
  src:  url(/static/media/Mobitrace-Transfusion.b9f96495.eot);
  src:  url(/static/media/Mobitrace-Transfusion.b9f96495.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-Transfusion.bf5a240b.ttf) format('truetype'),
    url(/static/media/Mobitrace-Transfusion.c0e2f3f2.woff) format('woff'),
    url(/static/media/Mobitrace-Transfusion.32f5873d.svg#Mobitrace-Transfusion) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Transfusion {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Transfusion' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-blood-end:before {
  content: "\20AC";
}
.ic-blood-fridge:before {
  content: "\2202";
}
.ic-blood-on-route:before {
  content: "\2021";
}
.ic-blood-on-ward:before {
  content: "\C2";
}
.ic-blood-on-ward-2:before {
  content: "\EA";
}
.ic-blood-ongoing:before {
  content: "\AE";
}
.ic-blood-paused:before {
  content: "\3C0";
}
.ic-blood-retour:before {
  content: "\D2";
}
.ic-blood-stopped:before {
  content: "\F4";
}
.ic-blood-transfusion:before {
  content: "m";
}
@font-face {
  font-family: 'Mobitrace-Transport';
  src:  url(/static/media/Mobitrace-Transport.8ef92a4f.eot);
  src:  url(/static/media/Mobitrace-Transport.8ef92a4f.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-Transport.ee0a9e2d.ttf) format('truetype'),
    url(/static/media/Mobitrace-Transport.7fe8dd86.woff) format('woff'),
    url(/static/media/Mobitrace-Transport.d28a6928.svg#Mobitrace-Transport) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-Transport {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-Transport' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-transport-cancel:before {
  content: "a";
}
.ic-transport-done:before {
  content: "b";
}
.ic-transport-ongoing:before {
  content: "d";
}
.ic-transport-pause:before {
  content: "e";
}
.ic-transport-request-b:before {
  content: "f";
}
.ic-transport-request-a:before {
  content: "g";
}
.ic-transport-request-c:before {
  content: "h";
}
.ic-transport-to-location-a:before {
  content: "i";
}
.ic-transport-to-location-b:before {
  content: "j";
}
.ic-transport-to-location-c:before {
  content: "k";
}
.ic-ambulance:before {
  content: "c";
}
.ic-stretcher:before {
  content: "\201D";
}
.ic-walking:before {
  content: "\E5";
}
.ic-wheelchair-person:before {
  content: "\BB";
}
@font-face {
  font-family: 'Mobitrace-UI';
  src:  url(/static/media/Mobitrace-UI.f12606df.eot);
  src:  url(/static/media/Mobitrace-UI.f12606df.eot#iefix) format('embedded-opentype'),
    url(/static/media/Mobitrace-UI.06a8d89a.ttf) format('truetype'),
    url(/static/media/Mobitrace-UI.cf029ec4.woff) format('woff'),
    url(/static/media/Mobitrace-UI.50a39980.svg#Mobitrace-UI) format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-Mobitrace-UI {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Mobitrace-UI' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-transport-cancel:before {
  content: "a";
}
.ic-transport-done:before {
  content: "b";
}
.ic-transport-ongoing:before {
  content: "d";
}
.ic-transport-pause:before {
  content: "e";
}
.ic-transport-request-b:before {
  content: "f";
}
.ic-transport-request-a:before {
  content: "g";
}
.ic-transport-request-c:before {
  content: "h";
}
.ic-transport-to-location-a:before {
  content: "i";
}
.ic-transport-to-location-b:before {
  content: "j";
}
.ic-transport-to-location-c:before {
  content: "k";
}
.ic-ambulance:before {
  content: "c";
}
.ic-stretcher:before {
  content: "\201D";
}
.ic-walking:before {
  content: "\E5";
}
.ic-wheelchair-person:before {
  content: "\BB";
}
.ic-battery-0-4:before {
  content: "\E6";
}
.ic-battery-1-4:before {
  content: "\C2";
}
.ic-battery-2-4:before {
  content: "\EA";
}
.ic-battery-3-4:before {
  content: "\AE";
}
.ic-battery-4-4:before {
  content: "\2020";
}
.ic-EWS:before {
  content: "v";
}
.ic-add:before {
  content: "l";
}
.ic-alert:before {
  content: "m";
}
.ic-arrow-drop-left:before {
  content: "n";
}
.ic-arrowdropright:before {
  content: "\DA";
}
.ic-arrowdropdown:before {
  content: "\BA";
}
.ic-arrow-drop-up:before {
  content: "o";
}
.ic-back:before {
  content: "p";
}
.ic-arrow-left:before {
  content: "q";
}
.ic-arrow-right:before {
  content: "r";
}
.ic-arrow-up:before {
  content: "M";
}
.ic-arrow-down:before {
  content: "s";
}
.ic-attention:before {
  content: "t";
}
.ic-attention-solid:before {
  content: "u";
}
.ic-badge:before {
  content: "\A3";
}
.ic-badge-solid:before {
  content: "%";
}
.ic-blood:before {
  content: "w";
}
.ic-browser:before {
  content: "\A1";
}
.ic-calendar:before {
  content: "x";
}
.ic-camera:before {
  content: "y";
}
.ic-cancel:before {
  content: "z";
}
.ic-check:before {
  content: "{";
}
.ic-cleaning:before {
  content: "|";
}
.ic-cloud:before {
  content: "^";
}
.ic-cloud-download:before {
  content: "\2013";
}
.ic-cloud-upload:before {
  content: "\D8";
}
.ic-coffee:before {
  content: "$";
}
.ic-connection:before {
  content: "[";
}
.ic-construction:before {
  content: "Q";
}
.ic-cool:before {
  content: "\192";
}
.ic-device:before {
  content: "\AB";
}
.ic-edit:before {
  content: "}";
}
.ic-equal:before {
  content: "~";
}
.ic-filter:before {
  content: "\7F";
}
.ic-flag:before {
  content: "`";
}
.ic-food:before {
  content: "\80";
}
.ic-forbidden:before {
  content: "=";
}
.ic-handwash:before {
  content: ":";
}
.ic-home:before {
  content: ",";
}
.ic-home-solid:before {
  content: ";";
}
.ic-hospital:before {
  content: "\B6";
}
.ic-hospital-solid:before {
  content: "\\";
}
.ic-info:before {
  content: "\220F";
}
.ic-info-solid:before {
  content: "\152";
}
.ic-injection:before {
  content: "\81";
}
.ic-location:before {
  content: "@";
}
.ic-loop:before {
  content: "\82";
}
.ic-medication:before {
  content: "\E9";
}
.ic-medication-alt:before {
  content: "&";
}
.ic-message-delay:before {
  content: "\BF";
}
.ic-message-delay-alt:before {
  content: "\131";
}
.ic-message-new:before {
  content: "'";
}
.ic-message-new-alt:before {
  content: "\"";
}
.ic-messages:before {
  content: "\A7";
}
.ic-messages-solid:before {
  content: "\F8";
}
.ic-messages-alt:before {
  content: "(";
}
.ic-messages-alt-solid:before {
  content: "\C7";
}
.ic-messages-attachment:before {
  content: ".";
}
.ic-messages-mail:before {
  content: "\203A";
}
.ic-messages-delivered:before {
  content: "\2044";
}
.ic-messages-read:before {
  content: "\A2";
}
.ic-messages-send:before {
  content: "\221A";
}
.ic-messages-send-solid:before {
  content: "\222B";
}
.ic-monitor:before {
  content: "\E8";
}
.ic-more:before {
  content: "\E7";
}
.ic-more-menu:before {
  content: "!";
}
.ic-more-solid:before {
  content: "\83";
}
.ic-navigation:before {
  content: "\2018";
}
.ic-notes:before {
  content: "\E0";
}
.ic-nurse:before {
  content: "\84";
}
.ic-nurse-solid:before {
  content: "*";
}
.ic-options:before {
  content: ")";
}
.ic-ordering:before {
  content: "-";
}
.ic-oxygen:before {
  content: "\2014";
}
.ic-oxygen-alt:before {
  content: "\153";
}
.ic-parameters:before {
  content: "#";
}
.ic-patient:before {
  content: "4";
}
.ic-patient-add:before {
  content: "1";
}
.ic-patient-check:before {
  content: "2";
}
.ic-patient-delete:before {
  content: "3";
}
.ic-patients:before {
  content: "5";
}
.ic-patients-solid:before {
  content: "\B4";
}
.ic-pause:before {
  content: "6";
}
.ic-people:before {
  content: "\2026";
}
.ic-people-female:before {
  content: "\2260";
}
.ic-people-male:before {
  content: "\F7";
}
.ic-photo:before {
  content: "7";
}
.ic-printer:before {
  content: "8";
}
.ic-pulse:before {
  content: "9";
}
.ic-questionaire-alt:before {
  content: "0";
}
.ic-radio-active:before {
  content: "\85";
}
.ic-radiobutton:before {
  content: "P";
}
.ic-radiobutton-checked:before {
  content: "N";
}
.ic-radiobutton-filled:before {
  content: "O";
}
.ic-refresh:before {
  content: "\FB01";
}
.ic-remove:before {
  content: "\C8";
}
.ic-research:before {
  content: "\25CA";
}
.ic-return:before {
  content: "\CC";
}
.ic-left:before {
  content: "\F8FF";
}
.ic-right:before {
  content: "\2248";
}
.ic-down:before {
  content: "\F9";
}
.ic-up:before {
  content: "\AC";
}
.ic-route-right:before {
  content: "\CF";
}
.ic-routeleft:before {
  content: "\E907";
}
.ic-routereverse:before {
  content: "\E908";
}
.ic-route:before {
  content: "\E909";
}
.ic-sample:before {
  content: "\B0";
}
.ic-sample-info:before {
  content: "\C6";
}
.ic-scan:before {
  content: "_";
}
.ic-scan-barcode:before {
  content: "\201E";
}
.ic-scan-icon:before {
  content: "\2039";
}
.ic-search:before {
  content: "A";
}
.ic-security:before {
  content: "\201C";
}
.ic-selection-off:before {
  content: "B";
}
.ic-selection-on:before {
  content: "C";
}
.ic-settings:before {
  content: "D";
}
.ic-signal:before {
  content: "E";
}
.ic-smiley:before {
  content: "\EB";
}
.ic-sorting:before {
  content: "/";
}
.ic-standby:before {
  content: "\2264";
}
.ic-statistics:before {
  content: "\D9";
}
.ic-stetho:before {
  content: "F";
}
.ic-stopwatch:before {
  content: "\2022";
}
.ic-stretcher1:before {
  content: "\201F";
}
.ic-tasks:before {
  content: "G";
}
.ic-tasks-solid:before {
  content: "\2019";
}
.ic-temperature:before {
  content: "H";
}
.ic-timeline:before {
  content: "I";
}
.ic-timer:before {
  content: "J";
}
.ic-tools:before {
  content: "R";
}
.ic-trash:before {
  content: "K";
}
.ic-tv:before {
  content: "\B5";
}
.ic-walking1:before {
  content: "\EC";
}
.ic-wheelchair-person1:before {
  content: "\BC";
}
.ic-wifi-disabled:before {
  content: "\C1";
}
.ic-wifi-disabled-alternative:before {
  content: "\DB";
}
.ic-wifi-high:before {
  content: "V";
}
.ic-wifi-low:before {
  content: "S";
}
.ic-wifi-mid:before {
  content: "T";
}
.ic-wifi-off:before {
  content: "U";
}
.ic-wound:before {
  content: "L";
}

